import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { media, fontsizes } from "../lib/style-utils";
import { Analytics } from "@vercel/analytics/react";

import Header from "../components/Header";
import HeaderHero from "../components/HeaderHero";
import Footer from "../components/Footer";
import Cta from "../components/buttons/CTA";

// styles
const Content = styled.div`
  p {
    font-size: ${fontsizes.fs_text_normal_mob};
    line-height: ${fontsizes.lh_text_normal_mob};
  }
  ${media.tablet`
    p{
      font-size: ${fontsizes.fs_text_normal};
      line-height: ${fontsizes.lh_text_normal};
    }
  `}
`;

// markup
const NotFoundPage = (data) => {
  return (
    <Content>
      <Header />
      <HeaderHero data={data.data.allContentfulPages} title={"404"} />
      <div className="py-8 md:py-12 px-4">
        <div className="container">
          <p className="pb-4">Ben je de weg kwijt?</p>
          <Cta text={"Ga naar Vacatures"} to={"/"} />
        </div>
      </div>
      <Footer />
      <Analytics />
    </Content>
  );
};

export default NotFoundPage;

export const query = graphql`
  query {
    allContentfulPages(filter: { titel: { eq: "Vacature pagina" } }) {
      nodes {
        titelOpBeeld
        headerBeeld {
          gatsbyImageData(
            width: 900
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;
